import React, { useCallback, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useMountedRef, useEventListener } from "@/hooks/CustomizationHooks";
import CloseBtn from "@/components/verification/svgicons/CloseBtn";
import { Progress } from "antd";
import WarningSVG from "./WarningSVG";

const TimeConut = React.forwardRef((props, ref) => {
  const time = props.time ? props.time : 180;
  const [show, setShow] = useState(false);
  const [timerCount, setTimerCount] = useState(time);
  const mountedRef = useMountedRef();
  const handleClose = () => setShow(false);
  useEffect(() => {
    const timer = setInterval(() => {
      if (mountedRef.current) {
        setTimerCount((i) => Math.max(i - 1, 0));
      } else {
        setTimerCount(time);
      }
    }, 1000);
    if (timerCount < 10) {
      if (timerCount == 0) {
        setShow(false);
        props.onTimeOut && props.onTimeOut();
      }
    } else if (timerCount == 10) {
      setShow(true);
    }
    return () => {
      clearInterval(timer);
    };
  }, [timerCount]);
  const handler = useCallback(({ clientX, clientY }) => {
    if (!show) {
      setTimerCount(time);
    }
  });

  useEventListener("mousemove", handler);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ borderRadius: "0.625rem" }}
    >
      <Modal.Body>
        <div
          onClick={handleClose}
          style={{ textAlign: "right", cursor: "pointer" }}
        >
          <CloseBtn />
        </div>

        <div
          className="w-100 d-flex flex-column align-items-center"
          style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
        >
          <div className="notes_title" style={{ marginBottom: "1.67rem" }}>
            <WarningSVG />
          </div>
          <div className="w-100">
            {timerCount <= 10 && (
              <Progress
                strokeLinecap="butt"
                percent={100 - (10 - timerCount) * 5}
                success={{
                  percent: (10 - timerCount) * 5,
                  strokeColor: "#f5f5f5",
                }}
                showInfo={false}
              />
            )}
          </div>
          <div style={{ ...styles.warning_content, margin: "2rem 0" }}>
            TIME OUT! You don't have any action in 180s. Please click to
            continue in{" "}
            <span style={{ color: "rgba(0,117,255,1)" }}>{timerCount}s</span>
            ,otherwise system will be closed in auto.
          </div>
          <div className="notes_btn" onClick={handleClose}>
            Continue
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

const styles = {
  warning_content: {
    color: "rgba(21,44,91,0.8)",
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: "1.13rem",
    lineHeight: "normal",
    letterSpacing: "0px",
    textAlign: "left",
  },
};
export default TimeConut;
