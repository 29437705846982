import React, { useState } from "react";
import ContentAbort from "@/pad/assets/svgicons/checkin/ContentAbort";
import { useNavigate } from "react-router-dom";
import { donorAPIPut } from "@/components/service/donor/donor";
const CancelDonorButton = ({ position, donorId, isChangeValue, visitId }) => {
  const [abortshow, setabort] = useState(false);
  const navigate = useNavigate();
  const handleAbort = async () => {
    await donorAPIPut("/visits/cancel", 
      {
        "visitId": visitId,
        "donorId": donorId,
        "position": position,
      }
    );
    setabort(false);
    isChangeValue && isChangeValue(false);
    navigate("/");
  };
  
  return (
    <div>
      <button className="btn_red" onClick={() => handleAbort()}>
        {" "}
        <ContentAbort /> Visit Cancel
      </button>
    </div>
  );
};
export default CancelDonorButton;
