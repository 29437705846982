import React, { useEffect, useState } from "react";
import SuccessIcon from '@/pad/assets/svgicons/donor/SuccessIcon'
import './ReportCheckIn.css'
import { Progress, Spin } from "antd";
import donorAPI from "@/api/app/donor/donor";
import moment from "moment";
import { firstToUpper } from "@/components/util/Util";
import Timening from "@/pad/assets/svgicons/checkin/Timening";
import { Col } from "react-bootstrap";

const ReportCheckIn = (props) => {
    const { dataID, donor_Id, queueData, setQueueData, handlerOnClickNext ,setBtnNext ,isPass ,handleFinish} = props
    const [donorData, setDonorData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [timeCount, setTimecount] = useState(100);
    const [countDown, setCountDown] = useState(180);

    useEffect(async () => {
        setIsLoading(true)
        setBtnNext(false)
        if (dataID) {
            let resDonor = await donorAPI.getList(dataID);
            if (resDonor) {
                setIsLoading(false)
                setDonorData(resDonor.data[0])
            }
        } else {
            setIsLoading(false)
        }
    }, [])

    let count = queueData && queueData.waitingCount ? queueData.waitingCount : 0;
    let waitingCount = count ? count : queueData && queueData.count ? queueData.count : 0;

 useEffect(() => {
      let timer = setInterval(() => {
        if (timeCount === 0) {
          handleFinish();
          clearInterval(timer);
        }
      }, 100);
      return () => {
        clearInterval(timer);
      };
    }, [timeCount]);
  
    useEffect(() => {
      let timer = setInterval(() => {
        if (countDown - 1 >= 0) {
          let time = countDown
          time = time - 1
          setCountDown(time)
        } else {
          handleFinish();
          clearInterval(timer);
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }, [countDown]);
    // Calculate the progress percentage
    const progressPercent = ((180 - countDown) / 180) * 100;
    return (
      <div className="pb-5">
        <div
          className="pt-4"
          style={{ color: "rgba(0, 117, 255, 1)", display: "none" }}
        >
          ({" "}
          <DownTimeMillisecond
            handlerOnClickNext={handlerOnClickNext}
            timeTotal={180}
          />
          s )
        </div>
        <Spin tip="Loading..." spinning={isLoading}>
          <div>
            <Col>
              {isPass != "Waiting" ? (
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    padding: "1rem",
                    backgroundColor: "#FFFAEF",
                    borderRadius: "10px",
                    marginTop: "1rem",
                  }}
                >
                  <div
                    className="finish_subtext"
                    style={{
                      color: isPass === "Pass" ? "#FFC875" : "#FFC875",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Timening />{" "}
                    <text style={{ position: "relative", top: "2px" }}>
                      {" "}
                      The page will auto close in {countDown}s
                    </text>
                  </div>
                  <div style={{ width: "72%", marginLeft: "1rem" }}>
                    <Progress
                      strokeLinecap="round"
                      trailColor="#FCEBCA"
                      percent={timeCount - progressPercent}
                      success={{
                        percent: timeCount - progressPercent,
                        strokeColor: "#FFC875",
                      }}
                      showInfo={false}
                    />
                  </div>
                </div>
              ) : null}
            </Col>
          </div>
          <div className="status_block mt-3">
            <div className="icon-cus">
              <SuccessIcon width="4rem" height="5rem" />
            </div>
            <p className="title">Checkin Successfully</p>
          </div>
          <div className="row g-0 d-flex align-items-center justify-content-center mb-5">
            <div className="col-12 col-sm-9 col-md-7 col-lg-6">
              <div className="created_card">
                <img
                  className="donor_image"
                  alt=""
                  src={`${donorData ? donorData.portrait[0] : ""}`}
                />
                <div className="row g-4 w-100">
                  <div className="col-6">
                    <ValueLebel
                      label="Donor Name"
                      value={`${
                        donorData
                          ? firstToUpper(donorData.firstName) +
                            " " +
                            firstToUpper(donorData.lastName)
                          : ""
                      }`}
                    />
                  </div>
                  <div className="col-6">
                    <ValueLebel
                      label="Donor ID"
                      value={`${donorData ? donorData.donorId : ""}`}
                    />
                  </div>
                  <div className="col-6">
                    <ValueLebel
                      label="Gender"
                      value={`${
                        donorData ? firstToUpper(donorData.gender) : ""
                      }`}
                    />
                  </div>
                  <div className="col-6">
                    <ValueLebel
                      label="DOB"
                      value={`${donorData ? donorData.birthdate : ""}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="line_dashed_details" style={{ margin: "2rem 0" }} />
          <div className="row g-0 d-flex align-items-center justify-content-center">
            <div className="col-12 col-sm-9 col-md-7 col-lg-8 px-4 mb-4">
              <p
                className="checkin_description"
              >
                Thank you for completing new donor creation process.You already
                checkin successfully. Please have a seat and wait for vour name
                to be called for a physical check.
              </p>
            </div>
            <div className="col-12 col-sm-9 col-md-7 col-lg-8">
              <div className="created_card_time">
                <p className="waiting-text">
                  <span>{waitingCount}</span>{" "}
                  {waitingCount && waitingCount > 1 ? "people" : "people"}{" "}
                  before you </p>
                <div><span className="estimated-text me-1">(estimated</span><span className="estimated-text20 me-1">{queueData ? queueData.waitingTime : "N/A"}</span><span className="estimated-text">mins
                at least)</span></div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
}

const DownTimeMillisecond = ({timeTotal, handlerOnClickNext}) => {
    const [countDown, setCountDown ] = useState(timeTotal ? timeTotal : 0)
    useEffect(() => {
      let timer = setInterval(() => {
        if (countDown - 1 >= 0) {
          let time = countDown
          time = time - 1
          setCountDown(time)
        } else {
          handleFinish();
          clearInterval(timer);
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }, [countDown]);
  
    const handleFinish = () => {
      handlerOnClickNext && handlerOnClickNext()
    };
  
    return countDown
  };
  

export default ReportCheckIn

const ValueLebel = (props) => {
    const { label, value } = props
    return (
        <div className='d-flex flex-column align-items-start'>
            <p className='label-text12'>{label}</p>
            <p className='value-text15'>{value}</p>
        </div>
    )
}