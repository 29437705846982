import Axios, { AxiosError } from "axios";
import { storageKeys } from "../../constants/storage";
Axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

//import { store } from "../../../employee/components/state/store";
//基地址

const BaseUrl = process.env.REACT_APP_BASE_URL;
//const TOKEN =
//  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMDc4ZmM4MDhjOGM4NjgzNDQ3YjlhNSIsImVtYWlsIjoiMTIzQGdtYWlsLmNvbSIsInNpdGVJZCI6IjYyZjQ3MWQxNjhlMmU2NzU1OTg5OTU5OCIsImlhdCI6MTY2NzUzMDYxNSwiZXhwIjoxNjY4Mzk0NjE1fQ.kMI96E-wftAaCvFmdZNVGKhaGkKgWw8-t20IoAHL20w";
const encodeParams = (params) => {
  const r = "?";
  const p = [];
  for (const key in params) {
    p.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
  }
  return r + p.join("&");
};

//请求类
class ApiService {
  constructor() {
    this.interceptorsOfReq();
    this.interceptorsOfRes();
  }

  get(url, params) {
    if (params) {
      url += encodeParams(params);
    }
    return Axios.get(BaseUrl + url).then((res) => res.data);
  }

  post(url, params) {
    if (!params) {
      params = {};
    }
    //if (store.getState().loginStateReducer.isLogin) {
    let token = localStorage.getItem(storageKeys.TOKEN);
    //let token = store.getState().loginStateReducer.isLogin;
    if (token) {
      let start = token.indexOf(".");
      let end = token.indexOf(".", start + 1);
      let employeeID = token.substring(start + 1, end);
      let employeeIDGet = JSON.parse(atob(employeeID));
      params.employeeId = employeeIDGet.id;
    }

    //}

    return Axios.post(BaseUrl + url, params).then((res) => res.data);
  }

  delete(url, params) {
    if (!params) {
      params = {};
    }
    return Axios.delete(BaseUrl + url, params).then((res) => res.data);
  }

  put(url, params, data) {
    if (params) {
      url += encodeParams(params);
      //url=url+'/'+params
    }
    if (!data) {
      data = {};
    }
    return Axios.put(BaseUrl + url, data).then((res) => res.data);
  }

  interceptorsOfReq() {
    Axios.interceptors.request.use(
      function (config) {
        if (!config.url.includes("localhost:9001")) {
          //if (store.getState().loginStateReducer.isLogin) {
          config.headers.Authorization = localStorage.getItem("user_token");
          config.headers.SiteId = localStorage.getItem(storageKeys.SITE);
          //store.getState().loginStateReducer.isLogin; 
          //}

          //config.headers.timeZone = -new Date().getTimezoneOffset() / 60;
        }
        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  //响应拦截器
  interceptorsOfRes() {
    Axios.interceptors.response.use(
      (response) => {
        //if (response.data.status === 200) {
        //  // console.log(response.data.message);
        //}
        // console.log(response.data.message);
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}

export default new ApiService();
