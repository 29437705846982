import { localeData } from "moment";
import React, { useEffect, useState } from "react";
import { Container, Col, Row, ProgressBar, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ContentAbort from "../../../../assets/svgicons/checkin/ContentAbort";
import RecommendModal from "../../modal/RecommendModal";
import {
  setQuestions,
  getDonor,
  putProcess,
} from "../../../service/donor/donor";
import Tipbox from "../../../../../components/Tipbox/Tipbox";
import Vector from "../../../../assets/svgicons/donor/Vector";
import moment from "moment";
import FormCard from "../../../card/FormCard";
import Recommend from "../../../../assets/svgicons/donor/Recommend";
import PassSVG from "../../../../assets/svgicons/donor/PassSVG";
import CanvelButton from "@/components/button/cancel/cancelButton";
import { message, Spin } from "antd";
import ReportLayout from "../createProfile/detailsPage/ReportLayout";
import FormLabel from "@/components/layout/LayoutCreation/formLabel";
import "./Report.css"
const ReportLiteracy = ({
  dataID,
  onRetake,
  setisPass,
  appCode,
  pendshow,
  setpend,
  questions,
  loading,
  setLoading
}) => {
  const [showLoading, setshowLoading] = useState(true);
  const [staffInf, setstaffInf] = useState(null);

  const navigate = useNavigate();

  const [literacyQ, setliteracyQ] = useState(null);
  const [showPass, setshowPass] = useState(true);

  const checkDonorPass = (questionList) => {
    let difficulty = questions.difficulty ? questions.difficulty : 0
    let record = questionList[questionList.length - 1].ids.questionId.record
    let findError = record.filter((item) => {
      if(
        (item.questionType.toLowerCase() === "multi-choice" ||
        item.questionType.toLowerCase() === "single-choice") && 
        item.answers
        ) {
          let isFail = item.answers.filter((answer) => answer && answer.isanswer && answer.result.toLowerCase() === "fail")
          if(isFail.length > 0) {
            return true
          }
      }
      return false
    })
    if(findError.length > difficulty) {
      return true
    }
    return false;
  }

  useEffect(async () => {
    let Wquestion = [];
    if (dataID.donorId) {
      let questionList = await getDonor("/donors/process", {
        donorId: dataID.donorId,
        process: "literacy",
      });
      if (questionList && questionList.length > 0) {
        setliteracyQ(questionList[questionList.length - 1]);
        setshowLoading(false);
        if (
          questionList &&
          questionList[questionList.length - 1] &&
          questionList[questionList.length - 1].employeeId
        ) {
          setstaffInf(questionList[questionList.length - 1].employeeId);
        }

        if (questionList[questionList.length - 1].ids?.questionId?.result) {
          if (
            questionList[questionList.length - 1].ids.questionId.result !=
            "pass"
          ) {
            if(checkDonorPass(questionList)) {
              setisPass(false);
            } 
            setshowPass(false);
          }
        }
      }
      setshowLoading(false);
    }
  }, []);

  let operator =
    literacyQ && literacyQ.employeeId
      ? (literacyQ.employeeId.firstName
        ? literacyQ.employeeId.firstName + " "
        : " ") +
      (literacyQ.employeeId.middleName
        ? literacyQ.employeeId.middleName + " "
        : " ") +
      (literacyQ.employeeId.lastName ? literacyQ.employeeId.lastName : " ")
      : "N/A";
  
  let countQ = 0
  return (
    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
      {showLoading ? (
        <Spin Tips="Loading..." />
      ) : (
        <ReportLayout>
          <div className=" d-flex flex-column align-items-center justify-content-center mb-4 w-100">
            <div className="icon-cus">{showPass ? <PassSVG width="4rem" height="5rem" /> : <Recommend width="4rem" height="5rem" />}</div>
            <p className="report_title_20 mt-3">
              {showPass ? "Pass" : "Please ask center staff for assistance"}
            </p>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="d-flex flex-row align-items-center" >
                <p className='create_donor_report_key label-text-left'>Completion Time :</p>
                <p className='create_donor_report_title'>
                  {literacyQ ? literacyQ.createdAt : "N/A"}
                  <span className="card_input_label">
                    &nbsp; {moment().local("en").format("a")}
                  </span>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="d-flex flex-row align-items-center row">
                <p className='create_donor_report_key col' style={{ marginRight: '5rem' }}>Operator :</p>
                <p className='create_donor_report_title col'>
                  {operator}
                </p>
              </div>
            </div>
          </div>

          <div className='line_dashed my-5'></div>

          <div>
            <FormLabel title="Information" />
          </div>

          <div style={{ marginTop: "1.5rem" }}>
            {literacyQ.ids.questionId.information ? (
              <FormCard title="Information">
                {literacyQ &&
                  literacyQ.ids &&
                  literacyQ.ids.questionId &&
                  literacyQ.ids.questionId.record[0] ? (
                  <div
                    className="literacy_consent_header"
                    dangerouslySetInnerHTML={{
                      __html:
                        literacyQ.ids.questionId.record[0].information,
                    }}
                  ></div>
                ) : null}
              </FormCard>
            ) : null}

            <div>
              <div>
                {literacyQ &&
                  literacyQ.ids &&
                  literacyQ.ids.questionId &&
                  literacyQ.ids.questionId.record.length > 0 &&
                  literacyQ.ids.questionId.record.map((item, ind) => {
                    countQ = item.article ? 1 : countQ + 1
                    return (<>
                      {item.article ? <div
                        className="w-100 literacy_consent_header"
                        style={{ marginTop: "1rem" }}
                        dangerouslySetInnerHTML={{
                          __html: item.article,
                        }}
                      >

                      </div> : ''}
                      <div className="question_layout row g-4 mb-3">
                        <div className="col-12 d-flex flex-row align-items-center">
                          <div className="question_number_layout me-4 ms-3-cus-">
                            Q{countQ}
                          </div>
                          <h4 className="question_title">{item.title}</h4>
                        </div>
                        <div className="col-12 d-flex flex-row align-items-center">
                          <div className="answer_line_layout me-4">
                            <div className="vertical_solid_line"></div>
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <p className="label-textAnswer mb-0">Answer:</p>
                            <div className="d-flex flex-wrap align-items-center answer_section">
                              {/* 选择题 */}
                              {(item.questionType.toLowerCase() == "multi-choice" ||
                                item.questionType.toLowerCase() ==
                                "single-choice") &&
                                item.answers &&
                                item.answers.map((answer) => (
                                  <div>
                                    {answer && answer.isanswer ? (
                                      <div
                                        className="preview_index_box"
                                        style={{
                                          padding: "0.61rem 1.25rem",
                                          margin: "0 1.25rem",
                                          border:
                                            answer.result.toLowerCase() === "fail"
                                              ? "2px solid #E44E51CC"
                                              : "none",
                                        }}
                                      >
                                        {answer.answer}
                                      </div>
                                    ) : null}
                                  </div>
                                ))}
                              {/* 简答题 */}
                              {item.questionType.toLowerCase() === "text" &&
                                item.answers ? (
                                <div
                                  className="preview_index_box"
                                  style={{
                                    padding: "0.61rem 1.25rem",
                                    margin: "0 1.25rem",
                                  }}
                                >
                                  {item.answers && item.answers[0]
                                    ? item.answers[0]
                                    : "N/A"}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>)
                  })}
              </div>
            </div>
            {pendshow && <RecommendModal
              // appCode={appCode}
              // pendshow={pendshow}
              // setpend={setpend}
              // dataID={dataID}
              // staffInf={staffInf}
              // onRetake={onRetake}
              loading={loading}
              setLoading={setLoading}
              appCode={appCode}
              pendshow={pendshow}
              setpend={setpend}
              dataID={dataID}
              staffInf={staffInf}
              processId={literacyQ && literacyQ?._id ? literacyQ?._id : ''}
              onRetake={() => onRetake(literacyQ && literacyQ?.ids && literacyQ?.ids?.questionId ? literacyQ?.ids?.questionId?._id : '')}
            />}
          </div>
        </ReportLayout>
      )}
    </div>
  );
};

export default ReportLiteracy;
